<template>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="box">
                        <HistoriqueOrdonnance />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import HistoriqueOrdonnance from '@/components/HistoriqueOrdonnance'

    export default {
        name: "OrdonnanceListe",
        components: {
            HistoriqueOrdonnance
        }
    }
</script>
